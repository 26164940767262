import { PageProps } from "gatsby";
import React from "react";

import { AccidentalSigns } from "../components/pages/AccidentalSigns";

import { snProps } from "../js/utils";

const AccidentalSignsPage = (props: PageProps) => (
  <AccidentalSigns {...{ ...snProps, ...props }} />
);

export default AccidentalSignsPage;
